/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { isOneOf as r } from "../ensureType.js";
import { process as i } from "./serializableProperty/originAliases.js";
import { create as n } from "./serializableProperty/reader.js";
import { process as e } from "./serializableProperty/shorthands.js";
import { create as t } from "./serializableProperty/writer.js";
function o(r, i) {
  return y(r, "any", i?.origin);
}
function s(r, i) {
  return y(r, "read", i?.origin);
}
function a(r, i) {
  return y(r, "write", i?.origin);
}
function y(r, i, n) {
  let e = r?.json;
  if (e?.origins && n) {
    let r;
    r = "link-chart" === n ? e.origins[n] && ("any" === i || i in e.origins[n]) ? e.origins[n] : e.origins["web-map"] : e.origins[n], r && ("any" === i || i in r) && (e = r);
  }
  return e;
}
function p(r) {
  const i = f(r);
  if (r.json.origins) for (const e in r.json.origins) {
    const o = r.json.origins[e],
      s = o.types ? u(o) : i;
    n(s, o, !1), o.types && !o.write && r.json.write?.enabled && (o.write = {
      ...r.json.write
    }), t(s, o);
  }
  n(i, r.json, !0), t(i, r.json);
}
function f(r) {
  return r.json.types ? j(r.json) : r.type ? c(r) : j(r);
}
function u(r) {
  return r.type ? c(r) : j(r);
}
function c(i) {
  if (!i.type) return;
  let n = 0,
    e = i.type;
  for (; Array.isArray(e) && !r(e);) e = e[0], n++;
  return {
    type: e,
    ndimArray: n
  };
}
function j(r) {
  if (!r.types) return;
  let i = 0,
    n = r.types;
  for (; Array.isArray(n);) n = n[0], i++;
  return {
    types: n,
    ndimArray: i
  };
}
function l(r) {
  e(r) && (i(r), p(r));
}
export { o as originSpecificPropertyDefinition, s as originSpecificReadPropertyDefinition, a as originSpecificWritePropertyDefinition, l as processPrototypePropertyMetadata };
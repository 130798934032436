/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function e(e) {
  if (e.json || (e.json = {}), o(e.json), n(e.json), r(e.json), e.json.origins) for (const t in e.json.origins) o(e.json.origins[t]), n(e.json.origins[t]), r(e.json.origins[t]);
  return !0;
}
function r(e) {
  e.name && (e.read && "object" == typeof e.read ? void 0 === e.read.source && (e.read.source = e.name) : e.read = {
    source: e.name
  }, e.write && "object" == typeof e.write ? void 0 === e.write.target && (e.write.target = e.name) : e.write = {
    target: e.name
  });
}
function o(e) {
  "boolean" == typeof e.read ? e.read = {
    enabled: e.read
  } : "function" == typeof e.read ? e.read = {
    enabled: !0,
    reader: e.read
  } : e.read && "object" == typeof e.read && void 0 === e.read.enabled && (e.read.enabled = !0);
}
function n(e) {
  "boolean" == typeof e.write ? e.write = {
    enabled: e.write
  } : "function" == typeof e.write ? e.write = {
    enabled: !0,
    writer: e.write
  } : e.write && "object" == typeof e.write && void 0 === e.write.enabled && (e.write.enabled = !0);
}
export { e as process };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../has.js";
import e from "../Logger.js";
import { getOrCreateMapValue as n } from "../MapUtils.js";
const r = () => e.getLogger("esri.core.accessorSupport.ensureTypes");
function t(e) {
  return null == e ? e : new Date(e);
}
function o(e) {
  return null == e ? e : !!e;
}
function u(e) {
  return null == e ? e : e.toString();
}
function a(e) {
  return null == e ? e : (e = parseFloat(e), isNaN(e) ? 0 : e);
}
function s(e) {
  return null == e ? e : Math.round(parseFloat(e));
}
function c(e) {
  return null;
}
function i(e) {
  return e?.constructor && void 0 !== e.constructor.__accessorMetadata__;
}
function l(e, n) {
  return null != n && e && !(n instanceof e);
}
function f(e) {
  return e && "isCollection" in e;
}
function p(e) {
  return e?.Type ? "function" == typeof e.Type ? e.Type : e.Type.base : null;
}
function y(e, n) {
  if (!n?.constructor || !f(n.constructor)) return v(e, n) ? n : new e(n);
  const r = p(e.prototype.itemType),
    t = p(n.constructor.prototype.itemType);
  return r ? t ? r === t ? n : r.prototype.isPrototypeOf(t.prototype) ? new e(n) : (v(e, n), n) : new e(n) : n;
}
function v(e, n) {
  return !!i(n) && (r().error("Accessor#set", "Assigning an instance of '" + (n.declaredClass || "unknown") + "' which is not a subclass of '" + h(e) + "'"), !0);
}
function g(e, n) {
  return null == n ? n : f(e) ? y(e, n) : l(e, n) ? v(e, n) ? n : new e(n) : n;
}
function h(e) {
  return e?.prototype?.declaredClass || "unknown";
}
const m = new WeakMap();
function d(e) {
  switch (e) {
    case Number:
      return a;
    case N:
      return s;
    case Boolean:
      return o;
    case String:
      return u;
    case Date:
      return t;
    case _:
      return c;
    default:
      return n(m, e, () => g.bind(null, e));
  }
}
function b(e, n) {
  const r = d(e);
  return 1 === arguments.length ? r : r(n);
}
function w(e, n, r) {
  return 1 === arguments.length ? w.bind(null, e) : n ? Array.isArray(n) ? n.map(n => e(n, r)) : [e(n, r)] : n;
}
function A(e, n) {
  return 1 === arguments.length ? w(n => b(e, n)) : w(n => b(e, n), n);
}
function $(e, n, r) {
  return 0 !== n && Array.isArray(r) ? r.map(r => $(e, n - 1, r)) : e(r);
}
function j(e, n, r) {
  if (2 === arguments.length) return r => j(e, n, r);
  if (!r) return r;
  r = $(e, n, r);
  let t = n,
    o = r;
  for (; t > 0 && Array.isArray(o);) t--, o = o[0];
  if (void 0 !== o) for (let u = 0; u < t; u++) r = [r];
  return r;
}
function k(e, n, r) {
  return 2 === arguments.length ? j(n => b(e, n), n) : j(n => b(e, n), n, r);
}
function M(e) {
  return !!Array.isArray(e) && !e.some(n => {
    const r = typeof n;
    return !("string" === r || "number" === r || "function" === r && e.length > 1);
  });
}
function S(e, n) {
  if (2 === arguments.length) return S(e).call(null, n);
  const t = new Set(),
    o = e.filter(e => "function" != typeof e),
    u = e.filter(e => "function" == typeof e);
  for (const r of e) "string" != typeof r && "number" != typeof r || t.add(r);
  let a = null,
    s = null;
  return (e, n) => {
    if (null == e) return e;
    const c = typeof e,
      i = "string" === c || "number" === c;
    return i && (t.has(e) || u.some(e => "string" === c && e === String || "number" === c && e === Number)) || "object" === c && u.some(n => !l(e, n)) ? e : (i && o.length ? (a || (a = o.map(e => "string" == typeof e ? `'${e}'` : `${e}`).join(", ")), r().error("Accessor#set", `'${e}' is not a valid value for this property, only the following values are valid: ${a}`)) : "object" == typeof e && u.length ? (s || (s = u.map(e => h(e)).join(", ")), r().error("Accessor#set", `'${e}' is not a valid value for this property, value must be one of ${s}`)) : r().error("Accessor#set", `'${e}' is not a valid value for this property`), n && (n.valid = !1), null);
  };
}
function T(e, n) {
  if (2 === arguments.length) return T(e).call(null, n);
  const t = {},
    o = [],
    u = [];
  for (const r in e.typeMap) {
    const n = e.typeMap[r];
    t[r] = b(n), o.push(h(n)), u.push(r);
  }
  const a = () => `'${o.join("', '")}'`,
    s = () => `'${u.join("', '")}'`,
    c = "string" == typeof e.key ? n => n[e.key] : e.key;
  return n => {
    if (e.base && !l(e.base, n)) return n;
    if (null == n) return n;
    const o = c(n) || e.defaultKeyValue,
      u = t[o];
    if (!u) return r().error("Accessor#set", `Invalid property value, value needs to be one of ${a()}, or a plain object that can autocast (having .type = ${s()})`), null;
    if (!l(e.typeMap[o], n)) return n;
    if ("string" == typeof e.key && !i(n)) {
      const r = {};
      for (const t in n) t !== e.key && (r[t] = n[t]);
      return u(r);
    }
    return u(n);
  };
}
class N {}
class _ {}
const B = {
  native: e => ({
    type: "native",
    value: e
  }),
  array: e => ({
    type: "array",
    value: e
  }),
  oneOf: e => ({
    type: "one-of",
    values: e
  })
};
function C(e) {
  if (!e || !("type" in e)) return !1;
  switch (e.type) {
    case "native":
    case "array":
    case "one-of":
      return !0;
  }
  return !1;
}
function D(e) {
  switch (e.type) {
    case "native":
      return b(e.value);
    case "array":
      return w(D(e.value));
    case "one-of":
      return F(e);
    default:
      return null;
  }
}
function F(e) {
  let n = null;
  return (t, o) => L(t, e) ? t : (null == n && (n = I(e)), r().error("Accessor#set", `Invalid property value, value needs to be of type ${n}`), o && (o.valid = !1), null);
}
function I(e) {
  switch (e.type) {
    case "native":
      switch (e.value) {
        case Number:
          return "number";
        case String:
          return "string";
        case Boolean:
          return "boolean";
        case N:
          return "integer";
        case Date:
          return "date";
        case _:
          return "null";
        default:
          return h(e.value);
      }
    case "array":
      return `array of ${I(e.value)}`;
    case "one-of":
      {
        const n = e.values.map(e => I(e));
        return `one of ${n.slice(0, -1)} or ${n[n.length - 1]}`;
      }
  }
  return "unknown";
}
function L(e, n) {
  if (null == e) return !0;
  switch (n.type) {
    case "native":
      switch (n.value) {
        case Number:
        case N:
          return "number" == typeof e;
        case Boolean:
          return "boolean" == typeof e;
        case String:
          return "string" == typeof e;
        case _:
          return null === e;
      }
      return e instanceof n.value;
    case "array":
      return !!Array.isArray(e) && !e.some(e => !L(e, n.value));
    case "one-of":
      return n.values.some(n => L(e, n));
  }
}
export { N as Integer, _ as Null, A as ensureArray, w as ensureArrayTyped, o as ensureBoolean, g as ensureClass, t as ensureDate, s as ensureInteger, D as ensureLongFormType, k as ensureNArray, j as ensureNArrayTyped, c as ensureNull, a as ensureNumber, S as ensureOneOf, T as ensureOneOfType, u as ensureString, b as ensureType, i as isClassedType, C as isLongFormType, M as isOneOf, l as requiresType, B as types };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "./Message.js";
class s extends e {
  constructor(e, s, t) {
    super(e, s, t);
  }
}
s.prototype.type = "warning";
export { s as default };
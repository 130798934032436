/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function n(n, t) {
  for (const [r, e] of n) if (t(e, r)) return !0;
  return !1;
}
function t(n, t) {
  for (const [r, e] of n) if (t(e, r)) return e;
  return null;
}
function r(n, t, r) {
  const e = n.get(t);
  if (void 0 !== e) return e;
  const o = r();
  return n.set(t, o), o;
}
function e(n) {
  const t = n.values().next();
  return !0 !== t.done ? t.value : null;
}
function o(n) {
  const t = new Map();
  return r => (t.has(r) || t.set(r, n(r)), t.get(r));
}
export { t as findInMap, e as first, r as getOrCreateMapValue, o as memoize, n as someMap };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../has.js";
import t from "../Logger.js";
import { pathToArray as n, getProperties as r } from "./utils.js";
import { Flags as e } from "./tracking/Flags.js";
const o = {
    onObservableAccessed: () => {},
    onTrackingEnd: () => {}
  },
  c = [];
let i = o;
function s(t) {
  i.onObservableAccessed(t);
}
let l = !1,
  a = !1;
function f(t, n, r) {
  if (l) return u(t, n, r);
  p(t);
  const e = n.call(r);
  return d(), e;
}
function g(t, n) {
  return f(o, t, n);
}
function u(n, r, e) {
  const o = l;
  l = !0, p(n);
  let c = null;
  try {
    c = r.call(e);
  } catch (i) {
    a && t.getLogger("esri.core.accessorSupport.tracking").error(i);
  }
  return d(), l = o, c;
}
function p(t) {
  i = t, c.push(t);
}
function d() {
  const t = c.length;
  if (t > 1) {
    const n = c.pop();
    i = c[t - 2], n.onTrackingEnd();
  } else if (1 === t) {
    const t = c.pop();
    i = o, t.onTrackingEnd();
  } else i = o;
}
function m(t, n) {
  if (n.flags & e.DepTrackingInitialized) return;
  const r = a;
  a = !1, n.flags & e.AutoTracked ? u(n, n.metadata.get, t) : k(t, n), a = r;
}
const y = [];
function k(t, r) {
  r.flags & e.ExplicitlyTracking || (r.flags |= e.ExplicitlyTracking, u(r, () => {
    const e = r.metadata.dependsOn || y;
    for (const r of e) if ("string" != typeof r || r.includes(".")) {
      const e = n(r);
      for (let n = 0, r = t; n < e.length && null != r && "object" == typeof r; ++n) r = A(r, e[n], n !== e.length - 1);
    } else A(t, r, !1);
  }), r.flags &= ~e.ExplicitlyTracking);
}
function A(t, n, e) {
  const o = "?" === n[n.length - 1] ? n.slice(0, -1) : n;
  if (null != t.getItemAt || Array.isArray(t)) {
    const n = parseInt(o, 10);
    if (!isNaN(n)) return Array.isArray(t) ? t[n] : t.at(n);
  }
  const c = r(t);
  if (c) {
    const n = c.propertiesByName.get(o);
    n && (s(n), m(t, n));
  }
  return e ? t[o] : void 0;
}
export { m as initializeDependencyTracking, f as runTracked, u as runTrackedNoThrow, g as runUntracked, s as trackAccess, k as trackExplicitDependencies };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import r from "../../../Error.js";
import e from "../../../Logger.js";
import { setDeepValue as t } from "../../../object.js";
import { isCollection as n } from "./type.js";
function i(r, e) {
  if (!e.write || e.write.writer || !1 === e.write.enabled && !e.write.overridePolicy) return;
  const t = r?.ndimArray ?? 0;
  r && (1 === t || "type" in r && n(r.type)) ? e.write.writer = a : t > 1 ? e.write.writer = l(t) : e.types ? Array.isArray(e.types) ? e.write.writer = f(e.types[0]) : e.write.writer = o(e.types) : e.write.writer = s;
}
function o(r) {
  return (e, t, n, i) => e ? u(e, r, i) ? s(e, t, n, i) : void 0 : s(e, t, n, i);
}
function u(t, n, i) {
  for (const r in n.typeMap) if (t instanceof n.typeMap[r]) return !0;
  if (i?.messages) {
    const o = n.errorContext ?? "type",
      u = `Values of type '${("function" != typeof n.key ? t[n.key] : t.declaredClass) ?? "Unknown"}' cannot be written`;
    i && i.messages && t && i.messages.push(new r(`${o}:unsupported`, u, {
      definition: t,
      context: i
    })), e.getLogger("esri.core.accessorSupport.extensions.serializableProperty.writer").error(u);
  }
  return !1;
}
function f(r) {
  return (e, t, n, i) => {
    if (!e || !Array.isArray(e)) return s(e, t, n, i);
    return s(e.filter(e => u(e, r, i)), t, n, i);
  };
}
function s(r, e, n, i) {
  t(n, p(r, i), e);
}
function p(r, e) {
  return r && "function" == typeof r.write ? r.write({}, e) : r && "function" == typeof r.toJSON ? r.toJSON() : "number" == typeof r ? y(r) : r;
}
function y(r) {
  return r === -1 / 0 ? -Number.MAX_VALUE : r === 1 / 0 ? Number.MAX_VALUE : isNaN(r) ? null : r;
}
function a(r, e, n, i) {
  let o;
  null === r ? o = null : r && "function" == typeof r.map ? (o = r.map(r => p(r, i)), "function" == typeof o.toArray && (o = o.toArray())) : o = [p(r, i)], t(n, o, e);
}
function c(r, e, t) {
  return 0 !== t && Array.isArray(r) ? r.map(r => c(r, e, t - 1)) : p(r, e);
}
function l(r) {
  return (e, n, i, o) => {
    let u;
    if (null === e) u = null;else {
      u = c(e, o, r);
      let t = r,
        n = u;
      for (; t > 0 && Array.isArray(n);) t--, n = n[0];
      if (void 0 !== n) for (let r = 0; r < t; r++) u = [u];
    }
    t(i, u, n);
  };
}
export { i as create, y as numberToJSON };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { handlesGroup as r } from "../handleUtils.js";
import { clone as n } from "../lang.js";
function t(r) {
  return r.__accessor__ ?? null;
}
function e(r, n) {
  return null != r?.metadata?.[n];
}
function i(r, n) {
  const e = t(r);
  return e ? e.getDependsInfo(r, n, "") : "";
}
function u(r, n, t) {
  if (t) {
    return o(r, n, {
      policy: t,
      path: ""
    });
  }
  return o(r, n, null);
}
function o(r, t, e) {
  return t ? Object.keys(t).reduce((r, i) => {
    const u = i;
    let a = null,
      l = "merge";
    if (e && (a = e.path ? `${e.path}.${i}` : i, l = e.policy(a)), "replace" === l) return r[u] = t[u], r;
    if ("replace-arrays" === l && Array.isArray(r[u])) return r[u] = t[u], r;
    if (void 0 === r[u]) return r[u] = n(t[u]), r;
    let s = r[u],
      c = t[u];
    if (s === c) return r;
    if (Array.isArray(c) || Array.isArray(r)) s = s ? Array.isArray(s) ? r[u] = s.concat() : r[u] = [s] : r[u] = [], c && (Array.isArray(c) || (c = [c]), c.forEach(r => {
      s.includes(r) || s.push(r);
    }));else if (c && "object" == typeof c) {
      if (e) {
        const n = e.path;
        e.path = a, r[u] = o(s, c, e), e.path = n;
      } else r[u] = o(s, c, null);
    } else r.hasOwnProperty(i) && !t.hasOwnProperty(i) || (r[u] = c);
    return r;
  }, r || {}) : r;
}
function a(r) {
  return r && ("string" != typeof r || r.includes(".")) ? l(r) : r;
}
function l(r) {
  return Array.isArray(r) ? r : r.split(".");
}
function s(r) {
  return r.includes(",") ? r.split(",").map(r => r.trim()) : [r.trim()];
}
function c(r) {
  if (Array.isArray(r)) {
    const n = [];
    for (const t of r) n.push(...s(t));
    return n;
  }
  return s(r);
}
function f(r) {
  if (!r.includes("?")) return null;
  const n = l(r),
    t = new Array(n.length);
  for (let e = 0; e < n.length; e++) {
    const r = n[e];
    t[e] = "?" === r[r.length - 1], t[e] && (n[e] = r.slice(0, -1));
  }
  return {
    fullPath: n.join("."),
    conditional: t
  };
}
function p(n, t, e, i) {
  const u = c(t);
  if (1 !== u.length) {
    const t = u.map(r => i(n, r, e));
    return r(t);
  }
  return i(n, u[0], e);
}
export { i as getDependsInfo, t as getProperties, e as isPropertyDeclared, u as merge, p as parse, f as parseConditionalPath, l as pathToArray, a as pathToStringOrArray, c as splitPath };
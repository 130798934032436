/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../../has.js";
import e from "../../Logger.js";
import { beforeDestroySymbol as t } from "../beforeDestroy.js";
import { Lifecycle as r } from "../interfaces.js";
import { getPropertiesMetadata as o } from "../metadata.js";
import { trackAccess as s } from "../tracking.js";
import { processPrototypePropertyMetadata as i } from "../extensions/serializableProperty.js";
const n = new Set(),
  c = new Set();
function a(e) {
  return r => {
    e ??= "esri.core.Accessor", r.prototype.declaredClass = e, p(r);
    const s = [],
      i = [];
    let a = r.prototype;
    for (; a;) a.hasOwnProperty("initialize") && !n.has(a.initialize) && (n.add(a.initialize), s.push(a.initialize)), a.hasOwnProperty("destroy") && !c.has(a.destroy) && (c.add(a.destroy), i.push(a.destroy)), a = Object.getPrototypeOf(a);
    n.clear(), c.clear();
    const l = class extends r {
      constructor(...e) {
        if (super(...e), this.constructor === l && "function" == typeof this.postscript) {
          if (s.length && Object.defineProperty(this, "initialize", {
            enumerable: !1,
            configurable: !0,
            value() {
              for (let e = s.length - 1; e >= 0; e--) s[e].call(this);
            }
          }), i.length) {
            let e = !1;
            const r = this[t];
            Object.defineProperty(this, "destroy", {
              enumerable: !1,
              configurable: !0,
              value() {
                if (!e) {
                  e = !0, r.call(this);
                  for (let e = 0; e < i.length; e++) i[e].call(this);
                }
              }
            });
          }
          Object.defineProperty(this, Symbol.dispose, {
            enumerable: !1,
            configurable: !0,
            value() {
              this.destroy();
            }
          }), this.postscript();
        }
      }
    };
    l.__accessorMetadata__ = o(r.prototype), l.prototype.declaredClass = e;
    const f = (e || "AccessorSubclass").split(".").slice(-1)[0];
    return Object.defineProperty(l, "name", {
      value: f,
      configurable: !0
    }), l;
  };
}
function l(e, t) {
  return null == t.get ? function () {
    const t = this.__accessor__,
      r = t.propertiesByName.get(e);
    if (void 0 === r) return;
    s(r);
    const o = t.store;
    return o.has(e) ? o.get(e) : r.metadata.value;
  } : function () {
    const t = this.__accessor__,
      r = t.propertiesByName.get(e);
    if (void 0 !== r) return r.getComputed(t);
  };
}
function p(e) {
  const t = e.prototype,
    s = o(t),
    n = {};
  for (const o of Object.getOwnPropertyNames(s)) {
    const e = s[o];
    i(e), n[o] = {
      enumerable: !0,
      configurable: !0,
      get: l(o, e),
      set(t) {
        const s = this.__accessor__;
        if (void 0 !== s) {
          if (!Object.isFrozen(this)) return s.initialized && e.readOnly ? f("read-only", o) : s.lifecycle === r.CONSTRUCTED && e.constructOnly ? f("construct-only", o) : void s.set(o, t);
        } else Object.defineProperty(this, o, {
          enumerable: !0,
          configurable: !0,
          writable: !0,
          value: t
        });
      }
    };
  }
  Object.defineProperties(e.prototype, n);
}
const f = (t, r) => {
  e.getLogger("esri.core.Accessor").error(`cannot assign to ${t} property '${r}'`);
};
export { p as finalizeClass, a as subclass };